<template>
  <div class="z-40 flex min-h-[calc(100vh-188px)] flex-col justify-between bg-slate-800 py-3 md:hidden">
    <!-- Top image -->
    <div class="flex w-full justify-center">
      <img
        class="w-1/3"
        :src="require('@/assets/bangabandhu-preview.png')"
        alt="Bangladesh"
      >
    </div>

    <!-- Mid nav -->

    <div class="xs:text-base flex flex-col px-10 text-xl font-semibold">
      <button class="flex items-center justify-between py-2 text-white">
        স্মার্ট বাংলাদেশ
        <img
          :src="require('@/assets/Icons/icon1.png')"
          alt="Smart Bangladesh"
          class="w-18"
        >
      </button>
      <button class="flex items-center justify-between py-2 text-white">
        স্মার্ট অর্থনীতি
        <img
          :src="require('@/assets/Icons/icon2.png')"
          alt="Smart economy"
          class="w-18"
        >
      </button>
      <button class="flex items-center justify-between py-2 text-white">
        স্মার্ট সরকার
        <img
          :src="require('@/assets/Icons/icon3.png')"
          alt="Smart gov"
          class="w-18"
        >
      </button>
      <button class="flex items-center justify-between py-2 text-white">
        স্মার্ট সমাজ
        <img
          :src="require('@/assets/Icons/icon4.png')"
          alt="Smart society"
          class="w-18"
        >
      </button>
    </div>

    <!-- Smart Bangladesh image -->
    <div class="flex w-full justify-center">
      <img
        :src="require('@/assets/pngtree-smart-bangladesh-bangla-typography-vector-design-png-image_6530326.png')"
        alt="Smart Bangladesh"
        class="w-1/2"
      >
    </div>
  </div>
</template>
